<template>
  <Form :heading1="'Upload a new document'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'Title'"
            :types="'text'"
            @input="setTitle"
            :not="true"
          />
          <TextInput
            :label="'Short description'"
            @input="setDescription"
            :types="'textarea'"
          />
          <!-- <TextInput :label="'Cluster'" :types="'text'" :not="true"/> -->
          <TextInput
            v-if="$store.state.auth.user.roles[0].name !== 'MINISTRY'"
            :label="'Cluster'"
            @input="setCluster"
            :types="'select'"
            :options="clusters"
            :not="true"
          />
        </div>
        <div class="flex-1">
          <TextInput
            v-if="$store.state.auth.user.roles[0].name !== 'MINISTRY'"
            :label="'Institution'"
            :types="'select'"
            @input="setIntitution"
            :options="institutions"
          />
          <br />
          <DragAndDrop :label="'File'" :allowZip="true" @input="setFile" />
        </div>
      </div>
      <Button :first-l="'Cancel'" :last-l="'Save'" @action="doSave" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import DragAndDrop from "/src/components/form/DragAndDrop.vue";
import Apis from "/src/services/Apis";
import { mapActions } from "vuex";

export default {
  name: "UploadNew",
  components: {
    Button,
    TextInput,
    Form,
    DragAndDrop,
  },
  data: () => ({
    institutions: [],
    clusters: [],
    clicked: false,
    document: {
      description: "",
      institutionId: "",
      clusterId: "",
      title: "",
    },
    file: null,
  }),
  methods: {
    setFile(value) {
      this.file = value;
    },
    setTitle(value) {
      this.document.title = value;
    },
    setDescription(value) {
      this.document.description = value;
    },
    setIntitution(value) {
      this.document.institutionId = value;
    },
    setCluster(value) {
      this.document.clusterId = value;
    },

    async getInstitutions() {
      await Apis.get("institutions")
        .then((res) => {
          this.institutions = res.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getClusters() {
      await Apis.get("clusters")
        .then((res) => {
          this.clusters = res.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapActions({ createDocument: "document/createDocument" }),
    doSave() {
      if(this.$store.state.auth.user.roles[0].name === 'MINISTRY'){
        this.setIntitution(this.$store.state.auth.moreDetails.institutionId);
        this.clusters.forEach(x=>{
          if(x.name == "DEFAULT"){
            this.setCluster(x.id)
            return
          }
        })
      }
      const { description, institutionId, clusterId, title } = this.document;
      if (
        description == "" ||
        institutionId == "" ||
        clusterId == "" ||
        title == "" ||
        !this.file
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else {
        setTimeout(() => {
          this.clicked = false;
        }, 5000);
        if (!this.clicked) {
          this.clicked = true;
          const formData = new FormData();
          formData.append("file", this.file);

          Apis.create(`documents`, this.document).then((result) => {
            Apis.configurable_update(
              "documents",
              `${result.data.data.id}/upload-document`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            ).then(() => {
              this.$store.dispatch("notification/SET_NOTIFICATION", {
                msg: "Document is successfully uploaded",
              });
              setTimeout(() => {
                this.$router.push(`/${this.$store.state.auth.user.roles[0].name === 'MINISTRY' ? 'ministry-':''}documents`);
              }, 1000);
            });
          });
        }
      }
    },
  },
  created() {
    this.getInstitutions();
    this.getClusters();
  },
};
</script>

<style>
</style>