<template>
  <div :class="`form-page ${heading1 ? '' : 'small'}`">
    <div>
      <div>
        <h1>{{ heading1 }}</h1>
        <!-- <h6>{{ heading6 }}</h6> -->
      </div>
      <slot name="details" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Form",
  props: {
    heading1: {
      type: String,
    },
    // heading6: {
    //   type: String,
    // },
  },
};
</script>

<style lang="scss">
.form-page {
  padding: 50px 100px;
  &.small {
    padding: 0;
  }
  text-align: left;
  font-family: Inter;
  font-style: normal;
  color: #032b3d;
  background-color: transparent;
  h1 {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
  // h6 {
  //   margin-bottom: 30px;
  //   font-weight: 500;
  //   font-size: 15px;
  //   line-height: 18px;
  //   color: #bcbdbe;
  // }
}
@media only screen and (max-width: 600px) {
  .form-page {
    padding: 50px;
    &.small {
      padding: 0;
    }
  }
}
@media only screen and (min-width: 768px) {
  .form-page {
    padding: 50px;
    &.small {
      padding: 0;
    }
  }
}
</style>
