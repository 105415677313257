<template>
  <div class="flex space-x-12" @click.prevent>
    <div class="flex-1">
      <button
        type="submit"
        id="first"
        class="focus:outline-none py-2"
        :disabled="disabling"
        @click="hasCancel ? $emit('cancel') : backClicked"
      >
        {{ firstL }}
      </button>
    </div>
    <div class="flex-1">
      <button
        type="submit"
        id="last"
        class="focus:outline-none py-2"
        @click="$emit('action')"
      >
        {{ lastL }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Buttons",
  props: {
    firstL: {
      type: String,
      required: true,
    },
    lastL: {
      type: String,
      required: true,
    },
    backUrl: {
      type: String,
    },
    hasCancel:{
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    disabling: false,
  }),
  methods: {
    backClicked() {
      if (!this.backUrl) this.$router.go(-1);
      else this.$router.push(this.backUrl);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/imports/colors.scss";
button {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  max-width: 390.28px;
  text-align: center;
  color: #ffffff;
  margin: 5px 0 25px;
}
#last {
  background-color: $primary;
  &:hover {
    background-color: #28a4e2c7;
  }
}
#first {
  background-color: #b6c0c9;
  &:hover {
    background-color: #c2cbd3;
  }
}
</style>
