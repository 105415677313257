<template>
  <div>
    <label :for="id" :class="`label ${focused ? 'colored' : ''}`">
      {{ label }}
      <span :class="`${not ? 'hidden' : ''}`">*</span>
    </label>
    <div @dragover.prevent @drop.prevent>
      <div @drop="handleFileDrop">
        <div class="file-wrapper">
          <input
            type="file"
            name="file-input"
            id="file"
            ref="file"
            @change="handleFileInput"
            @focus="focused = true"
            @blur="focused = false"
            :accept="
              allowZip
                ? 'application/zip,application/x-zip,application/x-zip-compressed,application/octet-stream, application/pdf, application/msword, application/vnd.ms-powerpoint, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                : 'application/pdf'
            "
          />
          <img src="../../assets/icons/upload-icon.svg" alt="" />

          <span v-show="!file">
            {{ cvPath ? cvPath : "Drop file here or click to pick one" }}
          </span>

          <!-- <ul class="inline-block" v-cloak>
                <li v-for="(file, index) in files" :key="index">
                    <span>{{ file.name }} </span>
                </li>
            </ul> -->
          <span v-if="file" class="inline-block">{{ file.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: "DragAndDrop",
  props: {
    label: {
      type: String,
      required: true,
    },
    allowZip: Boolean,
    not: Boolean,
    cvPath: String,
  },
  data: () => ({
    id: "",
    file: "",
    // files: [],
    // status: false,
    focused: false,
  }),
  methods: {
    handleFileDrop(e) {
      if (!e.dataTransfer.files) return;

      // this.files.push(...e.dataTransfer.files)
      this.file = e.dataTransfer.files[0];
    },
    handleFileInput(e) {
      if (!e.target.files) return;

      // this.files.push(...e.target.files)
      this.file = this.$refs.file.files[0];
      this.status = true;
      this.$emit("input", this.file);
    },
    // removeFile(fileKey: any){
    //     this.files.splice(fileKey, 1)
    //     if(this.files == []) this.status = false
    // },
  },
};
</script>

<style lang="scss">
.label {
  font-family: Manrope;
  font-size: 13px;
  line-height: 18px;
  color: #032b3d;
  span {
    color: #ff5473;
    padding-left: 5.97px;
  }
  &.colored {
    color: #4296bd;
  }
}

.file-wrapper {
  text-align: center;
  width: 385px;
  height: 157px;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  overflow: hidden;

  color: #ffffff;
  background: #b6c0c9;
  font-size: 13px;
  line-height: 18px;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;

  img {
    display: inline-block;
    padding-right: 32px;
  }

  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 200px;
  }
  [v-cloak] {
    display: none;
  }
}
</style>