<template>
  <div class="textInput">
    <label
      :for="id"
      :class="`label ${active ? 'colored' : ''}`"
      :title="not ? '' : 'required field'"
      >{{ label }} <span :class="`${not ? 'hidden' : ''}`">*</span></label
    >
    <div class="flex">
      <input
        v-show="types == 'text'"
        :type="types"
        v-model="value"
        @focus="active = true"
        @blur="active = false"
        :class="`${hasIcon ? 'small' : ''}`"
        :disabled="disabled"
        @change="valued"
        class="input"
      />

      <input
        v-show="types == 'password'"
        :type="types"
        v-model="value"
        @focus="active = true"
        @blur="active = false"
        :class="`${hasIcon ? 'small' : ''}`"
        @change="valued"
        class="input"
      />
      <div :class="`${hasIcon ? 'pass' : ''} ${active ? 'bordered' : ''}`">
        <slot name="pass-icon" />
      </div>
    </div>

    <input
      v-show="types == 'date'"
      :type="types"
      v-model="picked"
      @focus="active = true"
      @blur="active = false"
      @change="dateValued"
      :disabled="disabled"
      class="input"
    />

    <input
      :type="types"
      v-show="types == 'file'"
      name="file-input"
      accept="image/*"
      id="profile"
      ref="profile"
      @change="handleFileInput"
      @focus="focused = true"
      @blur="focused = false"
      class="input"
    />

    <input
      v-show="types == 'time'"
      :type="types"
      v-model="value"
      @focus="active = true"
      @blur="active = false"
      @change="valued"
      class="input"
    />

    <input
      v-show="types == 'number'"
      :type="types"
      v-model="value"
      @focus="active = true"
      @blur="active = false"
      :class="`${hasIcon ? 'small' : ''}`"
      :disabled="disabled"
      @change="valued"
      class="input"
    />

    <textarea
      v-show="types == 'textarea'"
      :rows="rows || 7"
      v-model="value"
      @focus="active = true"
      @blur="active = false"
      @change="valued"
      :class="max ? 'max' : ''"
    />

    <select
      v-show="types == 'select'"
      v-model="value"
      @focus="active = true"
      @blur="active = false"
      :class="`${small ? 'smallSelect' : ''}`"
      @change="valued"
    >
      <!-- <option disabled v-if="defaultValue != ''">{{ defaultValue }}</option> -->
      <!-- <option v-else></option> -->
      <option
        :selected="value == option.id"
        :value="option.id"
        v-for="option in options"
        :key="option.id"
      >
        {{ option.name }}
      </option>
    </select>

    <slot name="custom" />
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    label: {
      type: String,
      required: true,
    },
    types: {
      type: String,
      default: "text",
      required: true,
    },
    defaultValue: {
      default: "",
    },
    rows: {
      type: Number,
    },
    max: {
      type: Boolean,
    },
    options: {
      type: Array,
    },
    hasIcon: Boolean,
    small: Boolean,
    disabled: Boolean,
    not: Boolean,
  },
  data: () => ({
    id: "",
    file: "",
    value: "",
    picked: null,
    active: false,
    selectedOptions: [],
  }),
  methods: {
    valued() {
      console.log("updated");
      this.$emit("input", this.value);
    },
    dateValued() {
      if (this.picked) {
        this.$emit("input", this.picked);
      }
    },
    handleFileInput() {
      this.file = this.$refs.profile.files[0];
      this.status = true;

      this.$emit("input", this.file);
    },
    multiSelectValued() {
      this.$emit("input", this.selectedOptions);
    },

    isSelected(optionId) {
      return this.selectedOptions.includes(optionId);
    },
  },
  created() {
    this.value = this.defaultValue;
    if (this.types == "date") {
      // if (!this.defaultValue) return (this.picked = new Date());
      this.picked = this.defaultValue;
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/imports/colors.scss";
.textInput {
  .label {
    font-family: Manrope;
    font-size: 13px;
    line-height: 18px;
    color: #032b3d;
    span {
      color: #ff5473;
      padding-left: 5.97px;
    }
    &.colored {
      color: $primary;
    }
  }
  .input,
  textarea,
  select,
  option,
  #fieldsContainer {
    display: block;
    margin-bottom: 30px;
    padding: 15px;
    width: 100%;
    max-width: 389.19px;
    font-size: 13px;
    line-height: 18px;
    color: #032b3d;
    background: #ffffff;
    border: 1px solid rgba(207, 207, 207, 0.41);
    box-sizing: border-box;
    &.small {
      width: 84%;
    }
    &:focus {
      outline: 1px solid $primary;
    }
    &.max {
      max-width: 100%;
    }
  }
  #fieldsContainer {
    max-width: 477px;
    width: 100%;
    height: 253px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .field {
      width: 129px;
      height: 32px;
      border-radius: 8px;
      padding: 7px;
      background: #c4c4c4;
      margin: 15px 10px 0 0;
      cursor: pointer;
      &.active {
        background: #84ae62;
      }
    }
  }
  .input,
  select,
  option {
    height: 50px;
  }
  .v3dp__popout {
    padding: 0;
    width: 164.75px !important;
    & .v3dp__subheading {
      span {
        padding-right: 3px;
        font-size: 10px;
      }
    }
    .v3dp__elements button.selected span {
      background-color: #032b3d;
    }
    & button {
      padding: 1px;
    }
  }
  input[type="time"],
  .smallSelect {
    width: 164.75px !important;
  }
  .pass {
    background-color: #ffffff;
    height: 51.5px;
    padding: 12px 2px;
    margin: -1px;
    border: 1px solid rgba(207, 207, 207, 0.41);
    border-left: none;
    &.bordered {
      border: 1px solid $primary;
      border-left: none;
      i {
        color: $primary;
      }
    }
  }
}
</style>
